/* eslint-disable */

<template>
  <div class="vx-col">
    <vx-card class="mb-base">
      <div class="w-full flex justify-center items-center relative click-item-container" style="margin: 0rem 15rem">
        <div class="image-container flex items-center" style="position: relative">
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/02616959701502117544331/graphic.png"
            draggable="false"
            style="width: 15rem; height: 100%"
          />
          <div
            class="z-10 rounded-lg absolute bg-dark w-48 h-48 justify-center items-center p-0"
            style="left: -35%; padding: 0rem; border: 1px solid #182034"
          >
            <lottie-animation
              path="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/02616959701502117544331/internet.json"
              :loop="true"
              :autoPlay="true"
              :speed="1"
              :width="165"
            />
          </div>
        </div>

        <div class="flex flex-col flex-grow overflow-hidden relative justify-between" style="height: 34rem">
          <div class="rounded-lg bg-dark main-border border overflow-hidden" style="height: 16rem; width: 70%">
            <div class="flex bg-primary items-center justify-between p-2">
              <h5>480 University Ave - Toronto, ON Office</h5>
              <span
                class="bg-white rounded-lg text-black flex items-center gap-2 px-2 py-1 font-semibold text-sm cursor-pointer"
                @click="toronto_office.active = !toronto_office.active"
              >
                <font-awesome-icon icon="fa-regular fa-gear" class="h-4 w-4" />
                CONFIGURE
              </span>
            </div>

            <div class="flex gap-3 p-3">
              <div class="flex flex-col w-8/12 justify-center main-border border rounded-lg p-4" style="height: 11.5rem">
                <span class="flex items-center gap-2">
                  <h5>Gateway Brand:</h5>
                  <h5 class="text-grey font-normal">Sonicwall by DELL Inc.</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Gateway Model:</h5>
                  <h5 class="text-grey font-normal">NSa 2650 Enterprise</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Gateway S/N:</h5>
                  <h5 class="text-grey font-normal">SW-0918271 | <span class="text-white">FW:</span> 7.0.0</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>VPN Gateway:</h5>
                  <h5 class="text-grey font-normal">93.17.81.105/24 | 255.255.255.0</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Client Network:</h5>
                  <h5 class="text-grey font-normal">10.60.100.0/24 | 255.255.255.0</h5>
                </span>
              </div>

              <div class="flex flex-col w-4/12 main-border border rounded-lg p-3" style="height: 11.5rem">
                <span class="flex items-center gap-3">
                  <h5 class="text-primary font-medium">ISP Details</h5>
                  <span class="flex flex-grow bg-primary" style="height: 0.2rem" />
                </span>

                <span class="flex items-center gap-1 pb-2" style="border-bottom: 1px solid #182034">
                  <p>Throughput:</p>
                  <p class="text-grey font-normal">300Mbps</p>
                </span>
                <span class="flex items-center gap-1 pb-2" style="border-bottom: 1px solid #182034">
                  <p>Router Model:</p>
                  <p class="text-grey font-normal">CODA-5810</p>
                </span>
                <span class="flex items-center gap-1 pb-2">
                  <p>Link Speed:</p>
                  <p class="text-grey font-normal">1.0 GbE</p>
                </span>

                <span class="flex w-full justify-end">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/02616959701502117544331/rogers-business-logo.png"
                    draggable="false"
                    class=""
                    style="width: 40%; height: 100%"
                  />
                </span>
              </div>
            </div>
          </div>

          <div class="rounded-lg bg-dark main-border border overflow-hidden" style="height: 16rem; width: 70%">
            <div class="flex bg-primary items-center justify-between p-2">
              <h5>667 Madison Ave - NYC, NY Office</h5>
              <span
                class="bg-white rounded-lg text-black flex items-center gap-2 px-2 py-1 font-semibold text-sm cursor-pointer"
                @click="nyc_office.active = !nyc_office.active"
              >
                <font-awesome-icon icon="fa-regular fa-gear" class="h-4 w-4" />
                CONFIGURE
              </span>
            </div>

            <div class="flex gap-3 p-3">
              <div class="flex flex-col w-8/12 justify-center main-border border rounded-lg p-4" style="height: 11.5rem">
                <span class="flex items-center gap-2">
                  <h5>Gateway Brand:</h5>
                  <h5 class="text-grey font-normal">Ubiquiti Networks</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Gateway Model:</h5>
                  <h5 class="text-grey font-normal">Dream Machine Pro SE</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Gateway S/N:</h5>
                  <h5 class="text-grey font-normal">UI817HBGHAKIR | <span class="text-white">FW:</span> 4.0.20</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>VPN Gateway:</h5>
                  <h5 class="text-grey font-normal">71.48.76.22/24 | 255.255.255.0</h5>
                </span>
                <span class="flex items-center gap-2">
                  <h5>Client Network:</h5>
                  <h5 class="text-grey font-normal">192.100.1.0/24 | 255.255.255.0</h5>
                </span>
              </div>

              <div class="flex flex-col w-4/12 main-border border rounded-lg p-3" style="height: 11.5rem">
                <span class="flex items-center gap-3">
                  <h5 class="text-primary font-medium">ISP Details</h5>
                  <span class="flex flex-grow bg-primary" style="height: 0.2rem" />
                </span>

                <span class="flex items-center gap-1 pb-2" style="border-bottom: 1px solid #182034">
                  <p>Throughput:</p>
                  <p class="text-grey font-normal">1000Mbps</p>
                </span>
                <span class="flex items-center gap-1 pb-2" style="border-bottom: 1px solid #182034">
                  <p>Router Model:</p>
                  <p class="text-grey font-normal">CR1000A</p>
                </span>
                <span class="flex items-center gap-1 pb-2">
                  <p>Link Speed:</p>
                  <p class="text-grey font-normal">2.5 GbE</p>
                </span>

                <span class="flex w-full justify-end">
                  <img
                    src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/02616959701502117544331/vz-business-logo.svg.png"
                    draggable="false"
                    class=""
                    style="width: 40%; height: 100%"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <movable-popup
      refVal="movableNYCOfficeRef"
      title="NYC Office - VPN Gateway: Drag Here To Move"
      :active="nyc_office.active"
      v-on:close="nyc_office.active = false"
    >
      <template v-slot:content>
        <vs-tabs class="w-full">
          <vs-tab label="Phase 1" class="grid grid-cols-2 w-full gap-4">
            <span class="col-span-full" />
            <div v-for="field in nyc_office.phase1" :key="field.name" class="flex flex-col">
              <div>
                <h5 class="break-words mb-1">{{ field.name }}</h5>

                <v-select
                  v-if="field.type === 'dropdown'"
                  :options="field.options"
                  v-model="field.value"
                  :disabled="field.disabled"
                  class="w-full popup-dropdown"
                  :placeholder="'Select ' + field.name"
                />
                <vs-input
                  v-if="field.type === 'text'"
                  v-model="field.value"
                  :readonly="field.disabled"
                  class="inputx w-full self-center popup-input"
                />
                <div class="flex items-center" v-if="field.type === 'switch'">
                  <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                </div>
                <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
              </div>
            </div>
          </vs-tab>

          <vs-tab label="Phase 2" class="grid grid-cols-2 w-full gap-4">
            <span class="col-span-full" />
            <div v-for="field in nyc_office.phase2" :key="field.name" class="flex flex-col">
              <div>
                <h5 class="break-words mb-1">{{ field.name }}</h5>

                <v-select
                  v-if="field.type === 'dropdown'"
                  :options="field.options"
                  v-model="field.value"
                  :disabled="field.disabled"
                  class="w-full popup-dropdown"
                  :placeholder="'Select ' + field.name"
                />
                <vs-input
                  v-if="field.type === 'text'"
                  v-model="field.value"
                  :readonly="field.disabled"
                  class="inputx w-full self-center popup-input"
                />
                <div class="flex items-center" v-if="field.type === 'switch'">
                  <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                </div>
                <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableTorontoOfficeRef"
      title="Toronto Office - VPN Gateway: Drag Here To Move"
      :active="toronto_office.active"
      v-on:close="toronto_office.active = false"
    >
      <template v-slot:content>
        <vs-tabs class="w-full">
          <vs-tab label="Phase 1" class="grid grid-cols-2 w-full gap-4">
            <span class="col-span-full" />
            <div v-for="field in toronto_office.phase1" :key="field.name" class="flex flex-col">
              <div>
                <h5 class="break-words mb-1">{{ field.name }}</h5>

                <v-select
                  v-if="field.type === 'dropdown'"
                  :options="field.options"
                  v-model="field.value"
                  :disabled="field.disabled"
                  class="w-full popup-dropdown"
                  :placeholder="'Select ' + field.name"
                />
                <vs-input
                  v-if="field.type === 'text'"
                  v-model="field.value"
                  :readonly="field.disabled"
                  class="inputx w-full self-center popup-input"
                />
                <div class="flex items-center" v-if="field.type === 'switch'">
                  <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                </div>
                <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
              </div>
            </div>
          </vs-tab>

          <vs-tab label="Phase 2" class="grid grid-cols-2 w-full gap-4">
            <span class="col-span-full" />
            <div v-for="field in toronto_office.phase2" :key="field.name" class="flex flex-col">
              <div>
                <h5 class="break-words mb-1">{{ field.name }}</h5>

                <v-select
                  v-if="field.type === 'dropdown'"
                  :options="field.options"
                  v-model="field.value"
                  :disabled="field.disabled"
                  class="w-full popup-dropdown"
                  :placeholder="'Select ' + field.name"
                />
                <vs-input
                  v-if="field.type === 'text'"
                  v-model="field.value"
                  :readonly="field.disabled"
                  class="inputx w-full self-center popup-input"
                />
                <div class="flex items-center" v-if="field.type === 'switch'">
                  <vs-switch v-model="field.value" :disabled="field.disabled" class="self-center mr-2" />
                </div>
                <small v-if="field.score === false" class="mt-2 text-danger font-semibold">This answer is incorrect</small>
                <small v-if="field.score === true" class="mt-2 text-success font-semibold">This answer is correct</small>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';
import LottieAnimation from '../../../../../../../../components/lottie/LottieAnimation';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      nyc_office: {
        active: false,
        tab: 1,
        phase1: [
          {
            name: 'Peer IP Address',
            type: 'text',
            value: '',
            answer: '93.17.81.105',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Method',
            type: 'dropdown',
            value: '',
            answer: 'PSK',
            options: ['PKI', 'PSK', 'RADIUS'],
            disabled: false,
            score: null,
          },
          {
            name: 'Negotiation Mode',
            type: 'text',
            value: 'Main',
            answer: 'Main',
            disabled: true,
            score: null,
          },
          {
            name: 'Encryption Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'AES256',
            options: ['AES256', 'ECC secp160r1', '3DES'],
            disabled: false,
            score: null,
          },
          {
            name: 'Hash Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'SHA256',
            disabled: false,
            options: ['SHA256', 'MD5', 'SHA1'],
            score: null,
          },
          {
            name: 'DH Key Group',
            type: 'text',
            value: '14',
            answer: '14',
            disabled: true,
            score: null,
          },
        ],
        phase2: [
          {
            name: 'Mode',
            type: 'text',
            value: 'Tunnel',
            answer: 'Tunnel',
            disabled: true,
            score: null,
          },
          {
            name: 'Protocol',
            type: 'dropdown',
            value: '',
            answer: 'ESP',
            options: ['ESP', 'AH'],
            disabled: false,
            score: null,
          },
          {
            name: 'Encryption Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'AES256',
            options: ['Blowfish', 'AES256', '3DES'],
            disabled: false,
            score: null,
          },
          {
            name: 'Hash Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'SHA256',
            disabled: false,
            options: ['SHA256', 'MD5', 'SHA1'],
            score: null,
          },
          {
            name: 'Local Client Network Subnet',
            type: 'text',
            value: '',
            answer: '192.100.1.0/24',
            disabled: false,
            score: null,
          },
          {
            name: 'Remote Client Network Subnet',
            type: 'text',
            value: '',
            answer: '10.60.100.0/24',
            disabled: false,
            score: null,
          },
        ],
      },

      toronto_office: {
        active: false,
        phase1: [
          {
            name: 'Peer IP Address',
            type: 'text',
            value: '',
            answer: '71.48.76.22',
            disabled: false,
            score: null,
          },
          {
            name: 'Authentication Method',
            type: 'dropdown',
            value: '',
            answer: 'PSK',
            options: ['PKI', 'PSK', 'RADIUS'],
            disabled: false,
            score: null,
          },
          {
            name: 'Negotiation Mode',
            type: 'text',
            value: 'Main',
            answer: 'Main',
            disabled: true,
            score: null,
          },
          {
            name: 'Encryption Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'AES256',
            options: ['AES256', 'ECC secp160r1', '3DES'],
            disabled: false,
            score: null,
          },
          {
            name: 'Hash Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'SHA256',
            disabled: false,
            options: ['SHA256', 'MD5', 'SHA1'],
            score: null,
          },
          {
            name: 'DH Key Group',
            type: 'text',
            value: '14',
            answer: '14',
            disabled: true,
            score: null,
          },
        ],
        phase2: [
          {
            name: 'Mode',
            type: 'text',
            value: 'Tunnel',
            answer: 'Tunnel',
            disabled: true,
            score: null,
          },
          {
            name: 'Protocol',
            type: 'dropdown',
            value: '',
            answer: 'ESP',
            options: ['ESP', 'AH'],
            disabled: false,
            score: null,
          },
          {
            name: 'Encryption Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'AES256',
            options: ['Blowfish', 'AES256', '3DES'],
            disabled: false,
            score: null,
          },
          {
            name: 'Hash Algorithm',
            type: 'dropdown',
            value: '',
            answer: 'SHA256',
            disabled: false,
            options: ['SHA256', 'MD5', 'SHA1'],
            score: null,
          },
          {
            name: 'Local Client Network Subnet',
            type: 'text',
            value: '',
            answer: '10.60.100.0/24',
            disabled: false,
            score: null,
          },
          {
            name: 'Remote Client Network Subnet',
            type: 'text',
            value: '',
            answer: '192.100.1.0/24',
            disabled: false,
            score: null,
          },
        ],
      },
    };
  },
  methods: {
    markSimulation() {
      let total_score = 0;

      this.nyc_office.phase1.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.nyc_office.phase1[field_i].score = true;
          total_score++;
        } else {
          this.nyc_office.phase1[field_i].score = false;
        }
      });

      this.nyc_office.phase2.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.nyc_office.phase2[field_i].score = true;
          total_score++;
        } else {
          this.nyc_office.phase2[field_i].score = false;
        }
      });

      this.toronto_office.phase1.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.toronto_office.phase1[field_i].score = true;
          total_score++;
        } else {
          this.toronto_office.phase1[field_i].score = false;
        }
      });

      this.toronto_office.phase2.forEach((field, field_i) => {
        if (field.value === field.answer) {
          this.toronto_office.phase2[field_i].score = true;
          total_score++;
        } else {
          this.toronto_office.phase2[field_i].score = false;
        }
      });

      if (total_score === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', total_score);
      }

      this.$emit('simulation_getting_there', total_score);
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
    LottieAnimation,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx335452482824044544.scss';
</style>

<style>
.v-select .vs__dropdown-toggle {
  background: #02050d !important;
}

.theme-dark input {
  background: #02050d;
  color: #ffffff;
}
</style>
